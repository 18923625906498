// ================================================================================================
// 	File Name: email-application.scss
// 	Description: SCC file for email application page.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy React Admin Template
// 	Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Load variable overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

.email-application {
  // border: 1px solid $gray-300;
  border-radius: $border-radius-sm;
  height: calc(100vh - 12rem);
  // Sidebar area starts
  .sidebar-children {
    display: none;
  }
  .email-app-sidebar {
    width: $menu-expanded-width;
    height: calc(100vh - 13rem);
    background-color: $white;
    border-top-left-radius: $border-radius-sm;
    border-bottom-left-radius: $border-radius-sm;
    transition: all 0.3s ease;
    box-shadow: 0 0 15px 0 rgba($pure_black, 0.05) !important;
    border: 1px solid $gray-300;
    border-right: 0;
    overflow-y: hidden !important;
    // width: 100%;
    padding-bottom: 0.5rem;
    z-index: 3;

    // remove default overlay had to get it like this because there's no class for that
    & + div {
      display: none;
    }

    // Close Icon
    .sidebar-close-icon {
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;
      cursor: pointer;
      font-size: 1.25rem;
      z-index: 5;
      visibility: hidden;
    }
    .email-app-menu {
      width: 100%;
      // padding-bottom: 0.5rem;
      // z-index: 3;
      .compose-btn {
        padding: 1px 1.5rem;
        margin-bottom: 0.25rem;
      }
      .sidebar-menu-list {
        padding: 0 1.5rem;
        // position: relative;
        height: calc(100% - 6.4rem);
        .folder-text {
          color: $body-color;
        }
        // height: 100%;
      }
      .list-group .list-group-item i {
        top: 3px;
      }
      .email-pending-avatar {
        .avatar-content {
          height: 20px;
          width: 20px;
        }
      }
      hr {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }
    }
  }
  .list-group .list-group-item {
    padding-left: 0;
    padding-right: 0;
    &:hover,
    &:focus {
      background-color: transparent;
      color: $body-color;
    }
    &.active {
      background: transparent;
      color: $primary;
    }
  }
  // Sidebar area ends

  // Right content area common css starts
  .content-right {
    width: calc(100% - #{$menu-expanded-width});
    border: 1px solid $gray-300;
    position: relative;
    .content-wrapper {
      padding: 0;
    }
    // .email-app-list-wrapper {
    //   // border-left: 1px solid $gray-300;
    // }
    .app-fixed-search {
      padding: 0.35rem 0.1rem;
      border-bottom: 1px solid $gray-300;
      background-color: $white;
      border-top-right-radius: $border-radius;
      .sidebar-toggle {
        cursor: pointer;
        margin: 0.5rem 0.99rem;
        float: left;
        line-height: 1;
      }
      .form-control-position {
        font-size: calc(#{$spacer} * 1.1);
        z-index: 1;
      }
      input {
        border: 0;
        background-color: transparent;
        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
      }
    }
    .action-icon {
      cursor: pointer;
    }
  }
  // Right content area common css End

  // email list section
  .app-content {
    .content-area-wrapper {
      position: relative;
      // Sidebar toggle icon

      .go-back {
        cursor: pointer;
      }
    }
  }
  // Content area overlay
  .app-content-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: block;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    height: calc(100% - 1rem);
    &.show {
      visibility: visible;
      transition: all 0.3s ease;
      opacity: 1;
      background-color: rgba($pure_black, 0.2);
      border-radius: $border-radius;
    }
  }
  // Email user list area
  .email-user-list {
    position: relative;
    height: calc(100vh - 21.14rem);
    .users-list-wrapper {
      padding: 0;
      margin: 0;
      // Set delay per List Item
      @for $i from 1 through 5000 {
        li:nth-child(#{$i}) {
          animation-delay: 0.1s * $i;
        }
      }
      li {
        cursor: pointer;
        transition: all 0.2s;
        animation: fadeIn 0.25s linear;
        animation-fill-mode: both;
        position: relative;
        &.media {
          padding: ($spacer + 0.5) $spacer;
          &:hover {
            transform: translateY(-4px);
            box-shadow: 0 3px 10px 0 #ccc;
            transition: all 0.2s;
          }
          .media-left {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
          .media-body {
            width: calc(100% - 60px);
            overflow: hidden;
          }
        }
        .avatar,
        .avatar img {
          height: $avatar-size + 8;
          width: $avatar-size + 8;
        }
        .avatar img {
          border: 2px solid $white;
        }
        &.mail-read {
          background-color: $gray-200;
        }
        &:not(:first-child) {
          border-top: 1px solid $gray-300;
        }
        .user-details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.75rem;
          .list-group-item-text {
            color: $body-color;
          }
        }
        .mail-date {
          color: $body-color;
        }
        .mail-message {
          p {
            color: $body-color;
          }
        }
      }
      .user-action {
        display: flex;
        align-items: center;
        .user-checkbox {
          top: 2px;
        }
      }
    }
    .no-results {
      display: none;
      padding: 1.5rem;
      text-align: center;
      &.show {
        display: block;
      }
    }
  }
  // Action area of email list section
  .app-action {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-300;
    .action-left {
      .selectAll {
        margin: 0.25rem 0 0.108rem;
        .vs-checkbox {
          margin-right: 1rem;
        }
      }
    }
    .action-right {
      ul {
        li:not(:last-child) {
          margin-right: 1rem;
        }
      }
      .dropdown-toggle {
        color: $body-color;
        &::after {
          display: none;
        }
        white-space: normal;
      }
    }
  }
  // On click of mail Details section slide in from right
  // Email details section starts
  .email-app-details {
    position: absolute;
    display: block;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    top: 0;
    width: calc(100% - #{$menu-expanded-width});
    background-color: $body-bg;
    transform: translateX(100%);
    transition: all 0.3s ease;
    height: 100%;
    // padding: 1.5rem 0;
    border: 1px solid $gray-300;
    border-left: 0;
    height: calc(100vh - 13rem);
    &.show {
      visibility: visible;
      transition: all 0.3s ease;
      opacity: 1;
      transform: translateX(0%);
      transition: all 0.3s ease;
      width: 100%;
    }
    .email-detail-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 4px 6px rgba($pure_black, 0.04);
      border-bottom: 1px solid $kbd-bg;
      position: relative;
      padding: 1.5rem 2rem 0.5rem 2rem;
      background-color: $white;
    }
    .email-header-right {
      .dropdown-toggle {
        color: $body-color;
      }
    }
    // Email detail scroll area
    .email-scroll-area {
      padding: 0 2rem;
      position: relative;
      height: calc(100vh - 18.4rem);
      .email-detail-head {
        border-bottom: 1px solid $gray-300;
        padding: 1.5rem 1rem 0.75rem;
        .mail-meta-item {
          text-align: right;
        }
      }
      .card-body {
        &.mail-message-wrapper {
          border-bottom: 0px solid $gray-300 !important;
        }
      }
      .mail-label {
        letter-spacing: 0.25px;
      }
      .email-info-dropup {
        .dropdown-toggle::after {
          left: 0;
          margin: 0;
        }
      }
      .mail-message {
        p {
          margin-bottom: 1.5rem;
        }
      }
      .mail-attachements {
        text-transform: uppercase;
      }
    }
  }

  // Compose Mail Sidebar
  .compose-email {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    width: 400px;
    height: calc(100% - 1rem);
    border-radius: 0;
    transform: translateX(110%);
    transition: all 0.25s ease-in-out;
    opacity: 0;
    &.open {
      transform: translateX(0);
      opacity: 1;
    }
    .compose-mail-header {
      background: $body-bg;
      border-radius: 0;
      padding: 1rem;
      .close-compose-mail {
        position: absolute;
        top: 7px;
        right: 7px;
        cursor: pointer;
      }
    }
    .rdw-editor-wrapper {
      .rdw-editor-toolbar {
        .rdw-embedded-wrapper {
          .rdw-embedded-modal {
            left: -35px;
          }
        }
        .rdw-emoji-wrapper {
          .rdw-emoji-modal {
            left: -75px;
          }
        }
        .rdw-image-wrapper {
          .rdw-image-modal {
            left: -110px;
          }
        }
      }
    }
  }
}
// Modal form editor
#email-container {
  .demo-editor {
    min-height: 150px !important;
  }
}
@include media-breakpoint-down(sm) {
  .email-application {
    .sidebar-content {
      .sidebar-close-icon {
        visibility: visible !important;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .email-application {
    .sidebar-content {
      .sidebar-close-icon {
        visibility: visible !important;
      }
    }
    .app-content-overlay {
      z-index: 3;
    }
    .sidebar-left {
      position: relative;
      .email-app-sidebar {
        transform: translateX(-110%);
        transition: all 0.3s ease-in-out;
        // transition: transform .25s;
        left: 0;
        position: fixed;
        z-index: 5;
        left: -2px;
      }
      &.show {
        .email-app-sidebar {
          transform: translateX(13%);
          transition: all 0.3s ease;
          // transition: transform .25s;
          display: block;
        }
      }
    }
    .content-right {
      width: 100%;
      .app-fixed-search {
        border-top-left-radius: $border-radius;
        .form-group {
          width: 90%;
        }
      }
      .email-app-details {
        width: 100%;
        border-radius: $border-radius;
        .email-scroll-area {
          height: calc(100vh - 21.5rem);
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .email-application {
    .sidebar-left .email-app-sidebar {
      left: -1.12rem !important;
    }
    .compose-email{
      width: 300px;
    }
    .app-fixed-search {
      border-top-left-radius: $border-radius;
      .form-group {
        width: 88% !important;
      }
    }
    .email-detail-head .mail-meta-item {
      display: flex;
      justify-content: space-between;
      .mail-date,
      .mail-time {
        margin: 1rem 0.75rem 0 0;
      }
    }
    .app-content .content-right .email-app-details {
      .email-scroll-area {
        height: calc(100vh - 23.25rem);
      }
    }
    .app-content
      .content-area-wrapper
      .email-user-list
      .users-list-wrapper
      li
      .user-details {
      display: block;
      .mail-items {
        width: 70%;
        display: inline-grid;
      }
      .mail-meta-item {
        width: 15%;
        position: absolute;
        right: 1rem;
        top: 1.5rem;
        i,
        .bullet {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 349.98px) {
  .email-application .content-area-wrapper .sidebar .email-app-sidebar {
    width: 230px;
    left: 3px;
  }
  .email-application .app-content .content-area-wrapper .email-user-list {
    height: calc(100vh - 22.3rem);
  }
  .email-application .email-app-details .email-detail-header {
    padding: 0 1.2rem;
    .email-prev,
    .email-next {
      display: none;
    }
  }
}

// Keyframe animation
@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 100px;
  }
  75% {
    opacity: 0.5;
    top: 0px;
  }
  100% {
    opacity: 1;
  }
}
