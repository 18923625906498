@import '../node_modules/prismjs/themes/prism-tomorrow';
@import './assets/scss/app.scss';

.tabulator-col-content {
  background-color: #f8f8f8 !important;
  color: #626262 !important;
  border-color: #f8f8f8 !important;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  padding: 5px 5px !important;
}
.tabulator-col-content:hover {
  background-color: #cfcfcf !important;
}
.tabulator-header {
  background-color: transparent !important;
  border-color: transparent !important;
  border-width: 0px !important;
  height: 'fit-content' !important;
}

.tabulator-col-title {
  color: $primary !important;
}

.tabulator-arrow {
  border-bottom-color: #626262 !important;
  border-top-color: #626262 !important;
}

.tabulator {
  font-family: 'Montserrat', Helvetica, Arial, serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.tabulator-cell {
  border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
  padding: 5px 5px !important;
}

.tabulator-row {
  height: min-content !important;
  min-height: 0px !important;
}

.tabulator-tree-level-0.tabulator-row-even {
  color: #f8f8f8;
  background-color: $info !important;
}
.tabulator-tree-level-0.tabulator-row-odd {
  color: #f8f8f8;
  background-color: $primary !important;
}

.tabulator-tree-level-1 {
  color: #111111 !important;
  font-weight: 600 !important;
}

.comment:hover {
  background-color: #e9ecef;
  transition: all 600ms;
}

.commentInput {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.commentInputBtn {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.swiper-button-next, .swiper-button-prev {
  background-color: rgba(250, 250, 250, 0.5);
  height: 35px !important;
  width: 35px !important;
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgba(34, 41, 47, 0.15) !important;
}
.swiper-button-next::after {
  padding-left: 2px;
}
.swiper-button-prev::after {
  padding-right: 2px;
}

.sample-slider [class^="swiper-button-"]::after{
  color: $primary;
  font-size: 16px;
}

.swiper-pagination-bullet-active{
  background-color: $primary !important;
}

.ql-editor {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
