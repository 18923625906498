.p-organizationchart .p-organizationchart-line-down {
    background: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

tr.p-organizationchart-lines[style*="visibility: hidden"] td {
    border: none;
  }